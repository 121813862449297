import React from "react"
import { graphql, PageProps, Link } from "gatsby"
import { Button } from "../components/ui"
import { ArrowRight, ArrowLeft } from "react-feather"
import Layout from "../components/layout"
import { ContactQuery } from "./__generated__/ContactQuery"
import Done from "../components/done"
import Helmet from "react-helmet"

export default ({ data, location }: PageProps<ContactQuery>) => {
    return (
        <Layout
            seo={{
                title: "Thanks",
            }}
            location={location}
        >
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="container mx-auto h-screen py-12 px-12 content-center">
                <div className="title py-12 text-center">
                    <h2 className="font-black text-5xl text-color-1">
                        Thank you
                    </h2>
                </div>
                <Done />
                <div className="text-center mt-5">
                    お客様のご連絡を承りました。
                    <br />
                    またのご連絡をお待ちしております。
                </div>
                <div className="m-6 text-center">
                    <Link to="/">
                        <Button
                            title="ホームへ戻る"
                            type="button"
                            iconLeft={<ArrowLeft />}
                            // iconRight={<ArrowRight />}
                        />
                    </Link>
                </div>

                {/* <div className="flex flex-wrap pb-40">
                    {hasContactForm && (
                        <>
                            <div className="w-full mx-auto justify-center items-center">
                                <Form api={api_url} color="pink" />
                            </div>
                        </>
                    )}

                    <div
                        className={`w-full ${
                            hasContactForm ? "lg:w-1/2" : "lg:w-2/3 mx-auto"
                        } px-6 pt-8`}
                    >
                    <Description data={data.site.siteMetadata.contact} />
                
                    </div>
                </div> */}
            </div>
        </Layout>
    )
}
