import React, { useEffect, useRef } from "react"
import lottie from "lottie-web"

function Done() {
    const animationContainer = useRef<HTMLDivElement>(null)

    useEffect(() => {
        lottie.loadAnimation({
            loop: false,
            container: animationContainer.current,
            // path: "images/36888-done-check.json",
            path: "images/9917-success.json",
        })
    })

    return (
        <>
            <div className="h-64" ref={animationContainer} />
        </>
    )
}

export default Done
